import React from "react"
import styled from "styled-components"
import Project from "../Project"
import { Link } from "gatsby"

const PortfolioSection = styled.section`
  grid-area: articles;
  grid-column: 1 / -1;
  margin: 4rem 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @media (min-width: 992px) {
    grid-column: 2 /-2;
    grid-gap: 3rem;
    margin: 4rem 0 8rem 0;
  }
`

PortfolioSection.Intro = styled.div`
  grid-column: 1/-1;
  margin-bottom: 4rem;
  h2 {
    font-size: 1.8rem;
    line-height: 1.25;
    font-size: ${({ theme }) => theme.typography.h5};
    margin-bottom: 1.125rem;
  }
  a {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) {
    padding: 4rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 992px) {
    grid-column: 3 /-3;
    padding: 0;
    h2 {
      line-height: 1.2;
      font-size: ${({ theme }) => theme.typography.h4};
    }
  }
  @media (min-width: 1200px) {
  }
`

PortfolioSection.Intro.Subtitle = styled.h3`
  font-weight: 300;
  margin-bottom: 1.125rem;
  color: ${({ theme }) => theme.colors.subtitleColor};
  font-size: ${({ theme }) => theme.typography.h6};
`

const CtaLink = styled(Link)`
  font-size: 1.5rem;
  color: #18f0ae;
  font-weight: normal;
  text-decoration: none;
  transition: all 0.3s;
  &:after {
    content: " ⇢";
  }
  &:hover {
    color: white;
  }
`

export default ({ projects }) => {
  return (
    <PortfolioSection>
      <PortfolioSection.Intro>
        <PortfolioSection.Intro.Subtitle>
          Latest projects –
        </PortfolioSection.Intro.Subtitle>
        <h2>Websites that help your business grow and prosper online</h2>
        <CtaLink to="/work/">View all projects</CtaLink>
      </PortfolioSection.Intro>
      {projects.map(project => (
        <Project project={project} key={project.id} />
      ))}
    </PortfolioSection>
  )
}
