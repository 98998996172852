import React from "react"
import styled from "styled-components"
import CallToAction from "../../molecules/CallToAction"
import { StaticImage } from "gatsby-plugin-image"

const CtaSection = styled.section`
  grid-area: cta;
  grid-column: 1/-1;
  background: ${({ theme }) => theme.colors.accentColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
  }
`

const CtaImage = styled.div`
  background-image: ${({ theme }) => theme.colors.gradientTopRight};
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    flex-basis: 50%;
    padding: 3rem;
  }
  @media (min-width: 992px) {
    padding: 4rem;
    order: 2;
  }
`

export default () => {
  return (
    <CtaSection>
      <CallToAction
        subtitle="Why I'm different"
        title="My websites are fast, beautiful, and keep your visitors engaged."
      />
      <CtaImage>
        <StaticImage
          src="../../../images/tekjoe-thinking.png"
          alt="Tekjoe contemplating web design"
          height={600}
          width={1200}
          formats={["auto", "webp", "avif"]}
        />
      </CtaImage>
    </CtaSection>
  )
}
