import React from "react"
import styled from "styled-components"
import ExternalLink from "../../atoms/ExternalLink"
import SlideLink from "../../atoms/SlideLink"

const Project = styled.article`
  grid-column: 1/-1;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  p {
    margin-bottom: 1rem;
  }
  h5 {
    font-size: ${({ theme }) => theme.typography.h5};
    margin-bottom: 1.125rem;
    font-weight: bold;
    position: relative;
    color: ${({ theme }) => theme.colors.accentColorInverse};
    span {
      background: ${({ theme }) => theme.colors.linkUnderline};
      background-size: 100% 0.5rem;
      background-repeat: no-repeat;
      background-position: left 85%;
    }
  }
  @media (min-width: 768px) {
    padding: 2rem 4rem;
    margin-bottom: 0;
    p {
      font-size: 1.125rem;
    }
  }
  @media (min-width: 992px) {
    grid-column: span 6;
    margin: 0;
    padding: 0;
  }
  @media (min-width: 1200px) {
    grid-column: span 4;
  }
`

Project.Image = styled.div`
  padding: 2rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.gradientTopRight};
  height: 250px;
  margin-bottom: 1rem;
  display: flex;
  img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    flex: 1;
  }
  @media (min-width: 768px) {
    height: 300px;
    padding: 3rem;
  }
`

export default ({ project }) => {
  return (
    <Project>
      <Project.Image>
        <img src={project.mainImage.asset.url} alt={project.title} />
      </Project.Image>
      <h5>
        <span>{project.title}</span>
      </h5>
      <p>{project.body[0].children[0].text.substring(0, 110) + "..."}</p>
      <ExternalLink href={project.url}>
        <span>View Live Site</span>
      </ExternalLink>
    </Project>
  )
}
