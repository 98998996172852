import React from "react"
import { Layout } from "../components/organisms/Layout"
import Masthead from "../components/molecules/Masthead"
import SEO from "../components/seo"
import CTASection from "../components/organisms/CTASection"
import SignUp from "../components/organisms/SignUp"
import SlideLink from "../components/atoms/SlideLink"
import Portfolio from "../components/organisms/Portfolio"
import { graphql } from "gatsby"

export const query = graphql`
  query ProjectQuery {
    allSanityProject(limit: 3, sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        url
        body {
          children {
            text
          }
        }
        mainImage {
          asset {
            url
          }
        }
        id
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const projects = data.allSanityProject.nodes
  return (
    <Layout>
      <SEO title="Home" />
      <Masthead>
        <h1 style={{ marginBottom: "1rem" }}>
          Hey there! I'm Joe Ramirez. I build websites for small businesses and
          non-profit organizations.
        </h1>
        <SlideLink fontSize="h5" to="/about/">
          More about me
        </SlideLink>
      </Masthead>
      <CTASection />
      <Portfolio projects={projects} />
      <SignUp />
    </Layout>
  )
}

export default IndexPage
